import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route  
} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Home from "./containers/Home";
import logo from "./logo.jpg";

const styles = (theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});


class App extends React.Component {
  
  setActiveStep = (step) => {
    this.setState({activeStep: step});
  };

  applyUser = async (email, youtubeId) => {
    const response = await fetch('https://us-central1-brareytv.cloudfunctions.net/api/submit', {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        email,
        youtubeId
      })
    });

    if(response.ok) {
      return Promise.resolve();
    } else if(response.status === 400) {
      throw new Error("This email address is already entered in the giveaway.");
    } else {
      throw new Error("Entry failed to submit.  Please Retry.");
    }
  };


  render() {
    return (
      <div style={{height: "100%"}}>
        <div style={{height: 130, backgroundColor: "#ffffff"}}>
        <img src={logo} alt="brareytv" style={{margin: "0 auto", display: "block"}} />
        </div>
        <div style={{backgroundColor: "#444444", height: 5}} />
        <div style={{height: "100%"}}>       
      <Router>      
          <Switch>            
            <Route path="/">
              <Home setUser={this.applyUser} />
            </Route>
          </Switch>      
      </Router>
      </div>
      </div>
    );
  }
}


export default withStyles(styles)(App);