import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import FormHelperText from '@material-ui/core/FormHelperText';

function Home({setUser}) {
  const [validationEmailError, setValidationEmailError] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [validationYoutubeIdError, setValidationYoutubeIdError] = React.useState("");
  const [youtubeIdError, setYoutubeIdError] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [youtubeId, setYoutubeId] = React.useState("");
  const [error, setError] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [onSubmitting, setOnSubmitting] = React.useState(false);
  const [winner, setWinner] = React.useState("");
  
  const onSubmit = async (e) => {
    e.preventDefault();
    setOnSubmitting(true);
    setEmailError(false);
    setValidationEmailError("");
    setYoutubeIdError(false);
    setValidationYoutubeIdError("");
    var errors = false;
    if(email === "") {
      errors = true;
      setEmailError(true);
      setValidationEmailError("Email required");
    } 
    if(youtubeId === "") {
      errors = true;
      setYoutubeIdError(true);
      setValidationYoutubeIdError("Youtube display name required");
    } 
    if(!errors) {      
      try {
        await setUser(email, youtubeId);
        setSubmitted(true);
      } catch(err) {
        setError(err.message);
        setOnSubmitting(false);
      }
    }

    return false;
  }

  const pickWinner = async () => {
    const response = await fetch('https://us-central1-brareytv.cloudfunctions.net/api/winner', {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {       
        Accept: "application/json"
      }      
    });

    if(response.ok) {
      let winnerBody = await response.json();
      setWinner(winnerBody.user.youtubeId);
    } else {
      throw new Error("Winner not picked.");
    }
  }
  
  return (
    <Grid container direction="column" justify="center" style={{height:"100%"}}>          
      <div className="homecover">
        <div className="overlay">
          <Paper style={{width: 1000, margin: "0 auto", padding: 30, backgroundColor: "rgba(255,255,255,0.8"}}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
          <Typography variant="h1" style={{fontWeight: 600,color: "#000", textAlign:"center", marginBottom: 0, marginTop: 0, marginLeft: "auto", marginRight:"auto"}}>And the winner is...</Typography>
          </Grid>
        {/* <Paper style={{maxWidth: 600, minWidth: 350, padding: 20, marginBottom: 0, marginLeft: "auto", marginRight: "auto"}}>
          <form onSubmit={onSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <TextField id="outlined-basic" label="Email address" variant="outlined" error={emailError} helperText={validationEmailError} required fullWidth value={email} onChange={e => setEmail(e.target.value)} />
            </Grid>
            <Grid item>
              <TextField id="outlined-basic" label="YouTube display name" variant="outlined" required fullWidth error={youtubeIdError} helperText={validationYoutubeIdError} value={youtubeId} onChange={e => setYoutubeId(e.target.value)} />
            </Grid>
            <Grid item style={{alignSelf: "flex-end"}}>
              <Button variant="contained" color="primary" type="submit" onClick={onSubmit} disabled={onSubmitting}>
                Submit
              </Button>
            </Grid>
          </Grid>
          {error && <FormHelperText error>{error}</FormHelperText>}
          </form>
        </Paper> */}        
              <Grid item xs={12}>
                <Typography variant="h1" style={{fontWeight: 600,color: "#000", textAlign:"center", marginBottom: 0, marginTop: 0, marginLeft: "auto", marginRight:"auto"}}>Eden 0995</Typography>
              </Grid>
            </Grid>  
          </Paper>                           
        </div>   
      </div>
    </Grid>
  );
}

export default Home;